import React from "react";
import logo from "../assets/logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import Confetti from "react-confetti";
import { Button } from "reactstrap";

const Hero = () => {

  const {
    isAuthenticated,   
    loginWithRedirect 
  } = useAuth0();

return(
  
  <div className="text-center hero my-5">
    <img className="mb-3"
      src={logo}
      alt="Vula Labs logo" 
      width="75%"
    />

    {!isAuthenticated && (
      <div>
        <h5 className="text-muted mt-2 mb-5 text-center">This is a sample website that demonstrates our NFT authentication flow.</h5>
        <Button
          id="qsLoginBtn"                    
          color="primary"
          size="lg"
          style={{borderRadius: '100px'}}
          className="btn-margin mb-5"
          onClick={() => loginWithRedirect()}
        >
          Log in
        </Button>
      </div>
    )}

    {isAuthenticated && (   
      <div className="mt-2">        
        <h1>
          CONGRATULATIONS
        </h1>
        <p>
          You just signed in with an NFT! 
        </p>
        <Button                
        //tweet demo success graphic and add in link here 
          href="https://twitter.com/intent/tweet?text=VULA%20IS%20GOING%20GLOBAL%20@vulalabs"                          
          size="lg"          
          style={{borderRadius: '100px', backgroundColor: '#1DA1F2', borderColor: '#1DA1F2'}}
          className="my-3"                    
        >
        Share It!{' '}                    
        </Button>
        <Confetti>        
        </Confetti>
      </div>
    )}

  </div>
);
};

export default Hero;
