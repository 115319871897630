const contentData = [
  {
    image: "https://uploads-ssl.webflow.com/6369311a2686f8c173666103/636acdc14bdb1190d2493e2f_Checkout.png",
    name: "Checkout",
    title: "Purchase NFT",
    description:
      "VULA is available on your favorite NFT marketplace."
  },
  {
    image:"https://uploads-ssl.webflow.com/6369311a2686f8c173666103/636acdc239b40c9240e3e062_login.png",
    name: "Login",
    title: "Sign In",
    description:
      "Connect your wallet that holds our NFT."
  },
  {
    image:"https://uploads-ssl.webflow.com/6369311a2686f8c173666103/636acdc045fbf183b663ef68_Browse.png",
    name: "Browse",
    title: "Browse",
    description:
      "We verify ownership and sign you in."
  },
];

export default contentData;
