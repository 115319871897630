import React from "react";
import logo from "../assets/helmet.png";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <img className="mb-3"
      src={logo}
      alt="Vula Labs logo" 
      style={{
        height: 40,
        width: 31
      }}
    />
    <p>
      Integration with <a href="https://auth0.com">Auth0</a>
    </p>   
    <p className="f1 text-muted">
      Want to add our integration to your website? <a href="https://docs.vulalabs.com">Get started</a>
    </p> 
  </footer>
);

export default Footer;
