import React, { Component } from "react";

import { Row, Card, CardBody, CardTitle, CardText, CardImg } from "reactstrap";

import contentData from "../utils/contentData";

class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5">
        <h2 className="my-5 text-center">How It Works</h2>        
        <Row className="d-flex justify-content-between text-center">
          {contentData.map((col, i) => (
            <Card key={i} md={5} className="mb-4" style={{
              width: '18rem'           
            }}
            >
              <CardImg
                src={col.image}
                alt={col.name}
              >
              </CardImg>
              <CardBody>
                <CardTitle tag="h5">
                  {col.title}
                </CardTitle>
                <CardText
                  className="mb-2 text-muted"
                  tag="h6"
                  >
                  {col.description}
                </CardText>       
              </CardBody>
            </Card>
          ))}
        </Row>
      </div>
    );
  }
}

export default Content;
